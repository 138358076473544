import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Karsilama from './bilesenler/Karsilama'; 
import DetaySayfasi from './bilesenler/DetaySayfasi'; // DetaySayfasi bileşeni

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Karsilama />} />  
        <Route path="/:uuid" element={<Karsilama />} />  
        <Route path="/detay/:uuid" element={<DetaySayfasi />} />  {/* Detay sayfası için doğru rota */}
      </Routes>
    </Router>
  );
}

export default App;
