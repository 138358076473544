import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaShoppingCart, FaChevronDown, FaChevronUp, FaMoneyBillWave, FaDownload } from 'react-icons/fa'; // Yeni ikon
import Header from './header';
import Footer from './footer';
import './css/DetaySayfasi.css';

const DetaySayfasi = () => {
    const { uuid } = useParams();
    const [faturaDetay, setFaturaDetay] = useState(null);
    const [acik, setAcik] = useState(false);

    useEffect(() => {
        // API çağrısı
        fetch(`https://portal.karaoglufistik.com/api/fatura_cek_uuid.php?uuid=${uuid}`, {
            headers: {
                'X-API-KEY': '123456'
            }
        })
        .then(response => response.json())
        .then(data => setFaturaDetay(data))
        .catch(error => console.error('API hatası:', error));
    }, [uuid]);

    if (!faturaDetay) {
        return <div className="loading">Yükleniyor...</div>;
    }

    const { main, detay } = faturaDetay;
    const musteriAdi = main.CARIADI || ''; // Eğer isim yoksa boş bırakıyoruz
    const toplamTutar = main.TUTAR;

    // "Pdf fatura bilgisi: " kısmını temizleyip sadece URL'yi alıyoruz
    const eFaturaUrl = main.ISEFATURA === "0" ? faturaDetay.faturaBilgisi.replace("Pdf fatura bilgisi: ", "") : null;

    // Fatura indirme fonksiyonu
    const handleDownload = () => {
        if (main.ISEFATURA === "0" && eFaturaUrl) {
            window.open(eFaturaUrl, '_blank'); // Yeni sekmede fatura bağlantısını açar
        } else {
            alert("E-fatura sisteminizi kontrol ediniz."); // Eğer ISEFATURA 1 ise uyarı verir
        }
    };

    return (
        <div className="detay-sayfasi">
            <Header />
            {musteriAdi && (
                <div className="musteri-bilgisi">
                    <h2>Sayın {musteriAdi}</h2>
                </div>
            )}
            <div className="akordeon-container">
                <div className="akordeon-header" onClick={() => setAcik(!acik)}>
                    <div className="akordeon-left">
                        <FaShoppingCart className="sepet-icon" />
                        <div className="fatura-bilgisi">
                            <p>Fatura No: {main.BELGEKODU}</p>
                            <p>Tarih: {main.TARIH}</p>
                            <p>Toplam Tutar: {main.TUTAR} ₺</p>
                        </div>
                    </div>
                    <div className="akordeon-right">
                        {acik ? <FaChevronUp className="toggle-icon" /> : <FaChevronDown className="toggle-icon" />}
                    </div>
                </div>
                {acik && (
                    <div className="akordeon-content">
                        {detay.map((urun, index) => (
                            <div key={index} className="urun-detay">
                                <div className="urun-adi">{urun.STOKADI}</div>
                                <div className="urun-info">
                                    <span className="urun-miktar">Miktar: {urun.MIKTAR} {urun.BIRIM === 'KILO' ? 'Kg' : 'Adet'}</span>
                                    <span className="urun-fiyat">Fiyat: {urun.FIYAT} ₺</span>
                                    <span className="urun-tutar">Tutar: <strong>{urun.TUTAR} ₺</strong></span>
                                </div>
                            </div>
                        ))}

                        {/* Toplam Tutar Alanı */}
                        <div className="toplam-tutar">
                            <FaMoneyBillWave className="tutar-icon" />
                            <span>Toplam Tutar: {toplamTutar} ₺</span>
                        </div>

                        {/* Fatura İndirme Alanı */}
                        <div className="fatura-indirme" onClick={handleDownload}>
                            <FaDownload className="download-icon" />
                            <span>Faturanızı İndirin</span>
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default DetaySayfasi;
