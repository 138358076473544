import React from 'react';
import './css/header.css';
import logo from './images/logo.png'; // Logonun dosya yolunu bu şekilde belirle

const Header = () => {
    return (
        <header className="header-container">
            <div className="logo-container">
                <img src={logo} alt="Logo" className="logo" />
            </div>
            <div className="title-container">
                <h1>Alışveriş Detaylarınız</h1>
            </div>
        </header>
    );
};

export default Header;
