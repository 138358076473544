import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaFileInvoice, FaCartArrowDown, FaChartBar, FaBoxOpen } from 'react-icons/fa';
import './css/Karsilama.css';

const Karsilama = () => {
    const { uuid } = useParams(); // URL'den UUID alınıyor
    const navigate = useNavigate(); // Yönlendirme için kullanıyoruz
    const [musteriAdi, setMusteriAdi] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentLoadingText, setCurrentLoadingText] = useState({ text: '', icon: null }); 

    const loadingMessages = [
        { text: 'Fatura Bilgileri Yükleniyor...', icon: <FaFileInvoice /> },
        { text: 'Sepet Detayları Yükleniyor...', icon: <FaCartArrowDown /> },
        { text: 'Sepet Analizi Yükleniyor...', icon: <FaChartBar /> },
        { text: 'Ürün Detayları Yükleniyor...', icon: <FaBoxOpen /> }
    ];

    // Müşteri adını almak için API çağrısı ve yükleme animasyonunu başlatma
    useEffect(() => {
        if (uuid) {
            fetch(`https://portal.karaoglufistik.com/api/fatura_cek_uuid.php?uuid=${uuid}`, {
                headers: {
                    'X-API-KEY': '123456'
                }
            })
            .then(response => response.json())
            .then(data => {
                const adi = data.main.CARIADI || null;
                setMusteriAdi(adi);
                setLoading(false); // Yükleme bitti
            })
            .catch(error => console.error('API hatası:', error));
        }

        // Yükleme animasyonu döngüsü (sonsuz döngü)
        let index = 0;
        const interval = setInterval(() => {
            setCurrentLoadingText(loadingMessages[index]);
            index = (index + 1) % loadingMessages.length; // Sonsuz döngü
        }, 200); // Hızlı döngü, yarım saniyede bir değişim
        return () => clearInterval(interval); // Temizlik
    }, [uuid]);

    // 2 saniye sonra detay sayfasına yönlendirme
    useEffect(() => {
        if (!loading) {
            const timer = setTimeout(() => {
                navigate(`/detay/${uuid}`); // UUID ile detay sayfasına yönlendirme
            }, 2000);
            return () => clearTimeout(timer);
        }        
    }, [loading, navigate]);

    return (
        <div className="karsilama-container">
            <div className="karsilama-content">
                <h1 className="karsilama-title">Hoşgeldiniz</h1>
                {musteriAdi && <h2 className="musteri-adi">{musteriAdi}</h2>}
            </div>
            <div className="loading-section"> {/* Sonsuz döngüde çalışan bölüm */}
                <div className="loading-icon">{currentLoadingText.icon}</div>
                <p className="loading-text">{currentLoadingText.text}</p>
            </div>
            <footer className="footer">
                © {new Date().getFullYear()} Karaoğlu Kuruyemiş
            </footer>
        </div>
    );
};

export default Karsilama;
