import React from 'react';
import './css/footer.css';

const Footer = () => {
    return (
        <footer className="footer-container">
            © {new Date().getFullYear()} Karaoğlu Kuruyemiş
        </footer>
    );
};

export default Footer;
